import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LinkService, LinkDefinition } from "./link.service";
import { MetaTag } from "./model";
import { ActivatedRoute } from "@angular/router";

@Injectable({providedIn: 'root'})
export class HeadService {

    constructor(
        private meta: Meta,
        private route: ActivatedRoute,
        private title: Title,
        private linkService: LinkService,
        ) {
    }

    SetHead(title: string, metaTags: MetaTag[], links: LinkDefinition[]) {
        if (!title) { title = "Placebuzz"; }
        if (!metaTags) { metaTags = new Array<MetaTag>(); }
        if (!links) { links = new Array<LinkDefinition>(); }

        this.SetTitle(title);
        this.setRobotsForRequestsWithQuerystrings(metaTags);
        this.AddMetaTags(metaTags);
        this.AddLinks(links);
    }

    private SetTitle(title: string): void {
        this.title.setTitle(title);
    }

    private setRobotsForRequestsWithQuerystrings(metaTags: MetaTag[]): void {
        
        var setNoIndex = false;
        var updatedRobots = false;

        const queryParams = this.route.snapshot.queryParams;
        if (Object.keys(queryParams).length > 0) {
            for (var i = 0; i < metaTags.length; i++) {
                if (metaTags[i].name === "robots") {
                    metaTags[i].content = "noindex, nofollow";
                    updatedRobots = true;
                }
            }
    
            if (!updatedRobots) {
                metaTags.push({name:"robots", content:"noindex, nofollow"});
            }    
        }        
    }

    private AddMetaTags(metaTags: MetaTag[]): void {
        
        for (var i = 0; i < metaTags.length; i++) {
            if (metaTags[i].name) {
                this.meta.updateTag({name:metaTags[i].name, content:metaTags[i].content});
            }
            else if (metaTags[i].property) {
                this.meta.updateTag({property:metaTags[i].property, content:metaTags[i].content});
            }
        }
    }

    
    private AddLinks(links: LinkDefinition[]) {
        this.linkService.RemoveAllTags();
        for (var i = 0; i < links.length; i++) {
            this.linkService.AddTag(links[i]);
        }
    }

 

    
}