/* 
 * -- LinkService --        [Temporary]
 * @MarkPieszak
 * 
 * Similar to Meta service but made to handle <link> creation for SEO purposes
 * Soon there will be an overall HeadService within Angular that handles Meta/Link everything
 */

import { Injectable, PLATFORM_ID, RendererFactory2, ViewEncapsulation, Inject } from '@angular/core';

import { isPlatformServer, DOCUMENT } from '@angular/common';
import { CopyObject } from 'src/app/infrastructure/object.helpers';

@Injectable({providedIn: 'root'})
export class LinkService {

    private isServer: boolean = isPlatformServer(this.platform_id);
    private class = "placebuzz-meta-link";

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document,
        @Inject(PLATFORM_ID) private platform_id
    ) {
    }

    /**
     * Inject the State into the bottom of the <head>
     */
    AddTag(tag: LinkDefinition) {
        try {
            const renderer = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });

            const link = renderer.createElement('link');
            tag.class = this.class;

            Object.keys(tag).forEach((prop: string) => {
                return renderer.setAttribute(link, prop, tag[prop]);
            });

            renderer.appendChild(this.document.head, link);
        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }

    RemoveAllTags() {
        try {
            const renderer = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });

            let parents = [
                this.document.head,
                this.document.body
            ]

            for (var i = 0; i < parents.length; i++) {
                let parent = parents[i];
                if (parent === null) {
                    throw new Error('<head> not found within DOCUMENT.');
                }
                for (var i = 0; i < parent.children.length; i++) {
                    if (parent.children[i].className === this.class) {
                        renderer.removeChild(parent, parent.children[i]);
                    }
                }
            }
        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }

    private _parseSelector(tag: LinkDefinition): string {
        // Possibly re-work this
        const attr: string = tag.rel ? 'rel' : 'hreflang';
        return `${attr}="${tag[attr]}"`;
    }
}

export declare type LinkDefinition = {
    charset?: string;
    crossorigin?: string;
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    srcset?: string;
    sizes?: string;
    target?: string;
    type?: string;
    class?: string;
} & {
    [prop: string]: string;
};
